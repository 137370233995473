.color-primary{
  color: #3f51b5 !important;
    border: 4px solid black;
}

.bg-primary{
    background: #3f51b5 !important;
    border: 4px solid black !important;
}


.error-box{
    box-sizing: content-box;
    border: 2px solid red;
    border-radius: 5px;
    padding: 1rem;
    margin: 0 0.5rem  1rem 0.5rem !important;
    background: rgba(220,20,60,0);
    color: crimson;
}